
import { Component, Vue } from 'vue-property-decorator'
import Card from '@/components/Card/Card.vue';
import LargeIcon from '@/components/Icon/LargeIcon.vue';

@Component({ 
  components:{ 
    Card, 
    LargeIcon
  }
})
export default class NotFound extends Vue {

}
